.read {
	header.title {
		margin-bottom: 10px;
	}
}

.related {
	.title {
		font-weight: bold;
		margin: 0 0 10px 0;
	}

	#show_post_related {
		float: left;

		ul {
			float: left;
			padding: 0;
			margin: 10px 0;
			list-style: none;

			li {
				padding: 0 0 0 17px;
				background: url('img/icon.png') -254px -89px no-repeat;
				margin-bottom: 2px;
			}
		}
	}
}

.post-info-content {
	div,
	img {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
			height: auto !important;
		}
	}
}

.list_post {
	.logout {
		font-size: 16px;
	}

	.thumb {
		padding: 0;
	}

	.title {
		border-bottom: 2px solid $primary-color;
		line-height: 35px;
		height: 35px;
		margin-bottom: 10px;
	}

	.name {
		margin-bottom: 3px;
	}
}