.owl-product-category {
	margin-bottom: 15px;

	.name {
		a {
			font-weight: bold;
			text-transform: uppercase;
		}

		@media screen and (max-width: $mobile) {
			margin-top: 15px;
		}
	}

	.value {
		font-weight: bold;
	}
}