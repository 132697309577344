#footer {
	background-color: $primary-color;
	color: white;
	padding: 20px 0;
	font-size: 15px;
	float: left;
	width: 100%;

	p {
		font-size: 15px;
		line-height: 22px;
		color: white;
	}

	a {
		color: white;

		&:hover {
			color: white;
		}
	}

	h3 {
		text-transform: uppercase;
		border-bottom: 1px solid white;
		margin-bottom: 15px;
		padding-bottom: 6px;
	}

	table {
		width: calc(100% - 263px);
		float: left;

		@media screen and (max-width: $tablet) {
			width: 100%;
		}
	}

	#fanpage_facebook {
		float: left;

		@media screen and (max-width: $tablet) {
			width: 100%;
		}
	}

	.nav_footer {
		ul {
			list-style: none;

			li {
				display: inline-block;
				border-right: 1px solid white;
				height: 17px;

				&:last-child {
					border-right: none;
				}

				a {
					padding: 0 6px;
					font-weight: bold;
				}
			}
		}

	}
}