* {
  outline: none;
  font-family: Arial, sans-serif;
}

a {
	&:hover,
	&:active,
	&:focus {
		text-decoration: none !important;
	}
}

#call {
	position: fixed;
	bottom: 15px;
	display: block;
	left: 15px;
	z-index: 123;

	@media screen and (min-width: $tablet + 1px) {
		display: none;
	}

	.hotline {
		width: 50px;
	}
}

.owl-theme {
	.owl-dots {
		.owl-dot {
			&.active {
				span {
					background-color: $second-color;
				}
			}
		}
	}

	.owl-nav {
		position: absolute;
		top: 45px;
		left: 0;
		width: 100%;

		.owl-prev,
		.owl-next {
			background: transparent;

			&:hover {
				background: transparent;
			}

			.owl-left {
				background: url('img/owl-left.png');
				background-repeat: no-repeat;
				width: 35px;
				height: 40px;
				background-size: 13px auto;
				background-position: 12px 0px;
				position: absolute;
				left: 0;
			}

			.owl-right {
				background: url('img/owl-right.png');
				background-repeat: no-repeat;
				width: 35px;
				height: 40px;
				background-size: 13px auto;
				background-position: 12px 0px;
				position: absolute;
				right: 0;
			}
		}
	}
}

#wrapper {
	&:not(.home) {
		#sidebar_left {
			@media screen and (max-width: $tablet) {
				display: none;
			}
		}
	}

	#content {
		min-height: calc(100vh - 312px);
		margin-top: 10px;

		.title-page {
			margin: 0 0 10px 0;
		}
	}
}

a {
	color: $primary-color;

	&:hover {
		color: $primary-color;
	}
}

.product-table {
	thead {
		tr {
			td {
				background-color: $primary-color;
				text-transform: uppercase;
				color: white;
				text-align: center;

				&:first-child {
					text-align: left;
				}
			}
		}
	}

	tbody {
		&:hover {
			cursor: pointer;
		}

		tr {
			td {
				text-align: center;
				border-color: white;

				&:first-child {
					text-align: left;
				}

				img {
					margin-right: 10px;
					display: inline-block;
					float: left;
				}

				.name {
					float: left;
					display: block;

					&.has-img {
						width: calc(100% - 70px);

						@media screen and (max-width: $mobile) {
							padding-right: 70px;
						}
					}
				}
			}
		}
	}
}

.banner_under_slideshow {
	text-align: center;

	a {
		display: inline-block;
		margin-top: 20px;
	}
}

// Tab
#content ul.tabs {
	float: left;
	margin: 0;
	padding: 0;
}

#content ul.tabs li {
	float: left;
	height: 25px;
	line-height: 25px;
	border: 1px solid #cfcfcf;
	margin-bottom: -1px;
	overflow: hidden;
	position: relative;
	background: #e0e0e0;
	margin-right: 2px;
}

#content ul.tabs li a,#content ul.tabs li {
	border-radius: 5px 5px 0 0;
}

#content ul.tabs li a {
	text-decoration: none;
	color: #000;
	display: block;
	font-size: 13px;
	padding: 0 20px;
	border: 1px solid #fff;
	outline: none;
}

#content ul.tabs li a:hover {
	background-color: white;
}

#content ul.tabs li.active,
#content ul.tabs li.active a:hover {
	background-color: white;
	border-bottom: 1px solid white;
	font-weight: bold;
}

#content .tab_container {
	border: 1px solid #cfcfcf;
	overflow: hidden;
	clear: both;
	float: left;
	margin-bottom: 8px;
}

// Phân trang
.paginator {
	height: 40px;
	line-height: 20px;
	display: block;
	padding-top: 10px;
}

.paginator span {
	margin: 1px;
}
.paginator span a{
	color: #333;
	padding: 2px 5px;
	border: 1px solid #ccc;
}
.paginator span.current,#content .paginator a:hover {
	font-weight: bold;
	padding: 2px 5px;
	border: 1px solid #ccc;
	color: #cf0000;
}

.paginator a.disabled-page {
	color: #777;
}

.paginator a.disabled-page:hover {
	text-decoration: none;
	cursor: default;
}

// Lên đầu trang
#back-top {
	bottom: 25px;
	right: 15px;
	position: fixed;
	z-index: 9999;
}

#back-top a {
	color: #BBBBBB;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 26px;
	display: block;
	text-align: center;
}

#back-top a:hover {
	color: #000000;
}

#back-top span {
	background: darken($primary-color, 10)  url("img/up-arrow.png") no-repeat scroll center center;
	border-radius: 5px;
	width: 45px;
	height: 45px;
	display: block;
	margin-bottom: 7px;
}

#back-top a:hover span {
	background-color: darken($primary-color, 5) ;
}