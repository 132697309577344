.sp-wrap {
	background: white !important;
	border: 1px solid #eee !important;
	padding: 10px;
	max-width: none !important;
}

.sp-large a {
	text-align: center;
}

.sp-thumbs {
	margin-top: 15px;

	a {
		margin-bottom: 10px;
	}
}

.sp-lightbox {
	z-index: 1000 !important;
}