#slideshow-wrapper {
	float: left;
	width: 100%;

	#slideshow {
		margin: 0;
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}
}