$primary-color: #0072bc;
$second-color: #e32a2d;
$third-color: #ffc600;
$divider-color: #ddd;
$text-color: #333;
$right-boxes-space: 30px;
$mobile: 576px;
$tablet: 768px;

@import 'old-styles';
@import 'override_bootstrap';
@import 'flash_message';
@import 'global';
@import 'nav';
@import 'header';
@import 'footer';
@import 'home';
@import 'product';
@import 'contact';
@import 'category';
@import 'post';
@import 'counter';
@import 'comment';
@import 'applicant';
@import 'information';
@import 'smooth_product';
@import 'sidebar';
@import 'slideshow';
@import 'tag';
