.navbar {
	border: none;
	min-height: 0;
	margin-top: 10px;

	@media screen and (max-width: $mobile) {
		margin-top: 0;
	}

	.navbar-toggle {
		@media screen and (max-width: $mobile) {
			margin: 0 5px 0 0;
		}

		.icon-bar {
			background-color: $primary-color;
		}
	}

	.navbar-nav {
		float: right;

		@media screen and (max-width: $mobile) {
			margin: 0;
			float: right;
			padding: 0;
		}

		@media screen and (min-width: $mobile + 1px) and (max-width: $tablet) {
			margin-right: 10px;
		}

		> li {
			> a {
				padding: 0;

				@media screen and (max-width: $tablet) {
					padding: 5px 0;
				}
			}

			a {
				line-height: 26px;
				text-shadow: none;
				word-spacing: 0.6px;
				background-image: none !important;
				color: $text-color;
				font-weight: bold;
				text-transform: uppercase;
				background-color: white !important;

				&.current,
				&:hover {
					color: $primary-color;
					background: white;
				}

				.submenu-caret-wrapper {
					display: inline-block;

					@media screen and (max-width: $tablet) {
						padding: 0px 10px 0 10px !important;
					}
				}
			}

			&.current,
			&:hover {
				color: $primary-color;
				background: white;
			}

			.dropdown-toggle {
				a:focus {
				}
			}

			&.dropdown {
				.dropdown-menu {
					border-radius: 0;
					padding: 0;
					margin: 0;

					li {
						border-radius: 0;

						&:hover {
							background-color: $divider-color;
						}
					}
				}
			}
		}
	}
}