#header_top {
	display: none;
}

#header {
	border-bottom: 5px solid $primary-color;
	padding: 15px 0 5px 0;

	.logo-desktop {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	#logo {
		img {
			max-height: 80px;

			@media screen and (max-width: $tablet) {
				max-height: 40px;
			}
		}

		&.logo-mobile {
			@media screen and (min-width: $tablet + 1px) {
				display: none;
			}
		}
	}

	.searchbox-wrapper {
		text-align: right;

		.navbar-toggle {
			.icon-bar {
				background-color: $primary-color;
			}
		}
	}

	.hotline {
		margin: 10px 0 0 0;
		font-weight: bold;
		font-size: 14px;
		display: block;
		float: left;
		text-align: center;
		width: 100%;

		@media screen and (min-width: $tablet + 1px) {
			width: calc(100% - 350px);
		}

		a {
			color: $second-color;

			&:hover {
				text-decoration: none;
			}
		}

		@media screen and (min-width: $tablet + 1px) {
			font-size: 16px;
		}

		.mobiphone {
			background: url('img/mobiphone.png') 0 0 no-repeat;
			height: 17px;
			width: 17px;
			background-size: 17px;
			margin-right: 5px;
			display: inline-block;
			line-height: 17px;
		}

		.slash {
			display: inline-block;
			color: $second-color;
		}

		.tel {
			display: inline-block;
		}
	}

	.search {
		width: 300px;
		margin: 0;
		padding: 0;
		border: 2px solid $primary-color;
		background-color: white;
		display: inline-block;
		vertical-align: top;
		position: relative;

		@media screen and (max-width: $tablet) {
			display: none;
		}

		input {
			background: none;
			border: none;
		}

		ul {
			margin: 0;
			padding: 0;
			float: left;
			list-style: none;
			width: 100%;

			li {
				float: left;

				&.input_padding {
					width: calc(100% - 30px);
					position: relative;

					input {
						font-size: 13px;
						width: 100%;
						float: left;
						padding: 0 8px;
						height: 30px;
					}

					@media screen and (max-width: $mobile) {
						width: 158px;
					}

					#search_loading {
						position: absolute;
						right: 5px;
						top: 3px;
					}
				}

				&.find_header {
					background-color: $primary-color;

					.submit {
						border: none;
						background: none;
						width: 30px;
						height: 30px;
						padding: 0;

						.glyphicon {
							color: white;
							font-size: 15px;
							line-height: 30px;
							vertical-align: top;
						}
					}
				}
			}
		}

		.autocomplete-suggestions {
			position: absolute;
			background-color: white;
			border: 1px solid $divider-color;
			top: 32px;
			width: calc(100% + 4px);
			left: -2px;
			right: -2px;
			z-index: 10;
			padding: 5px;
			box-sizing: border-box;
			max-height: 500px;
			overflow-y: auto;
			display: none;

			.autocomplete-suggestion-list {
				float: left;
				width: 100%;

				.autocomplete-suggestion {
					float: left;
					width: 100%;
					border-bottom: 1px dashed $divider-color;
					padding: 5px 0;
					box-sizing: border-box;

					&:last-child {
						padding-bottom: 0;
						border-bottom: none;
					}

					.thumb {
						float: left;
					}

					.infos {
						float: left;
						margin-left: 10px;
						width: calc(100% - 90px);

						.name {
							text-align: left;
						}

						.price {
							text-align: left;

							.product-price {
								color: red;
							}
						}
					}
				}
			}

			.not-found {
				text-align: center;
				color: red;
			}
		}
	}
}