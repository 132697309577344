#product-list {
	.thumb {
		text-align: center;
	}
}

.product-item {
	.name {
		a {
			color: $text-color;

			&:hover {
				color: $text-color;

			}
		}
	}
}

.product-grid-item {
	margin-bottom: 30px;

	.name {
		padding: 0;
		margin-bottom: 10px;
		border-bottom: 1px solid white;
		text-transform: uppercase;

		a {
			text-transform: uppercase;
		}
	}

	.infos {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 10px;
		text-align: center;

		.detail {
			float: right;

			.glyphicon-chevron-right {
				color: $second-color;
				font-size: 8px;
				vertical-align: top;
				margin-top: 5px;
			}
		}

		.contact-link {
			&:hover {
				color: #e32a2d;
			}
		}
	}
}

.product-view {
	.images {
		@media screen and (min-width: $tablet) {
			padding: 0;
		}
	}

	.summary {
		width: 100%;
		float: left;
		border: 1px dashed $primary-color;
		padding: 20px;
		box-sizing: border-box;
		margin: 10px 0;
	}

	.product_des {
		float: left;
		width: 100%;

		.tab_container {
			width: 100% !important;

			.tab_content {
				padding: 10px;

				img {
					max-width: 100%;
					height: auto !important;
				}
			}
		}
	}

	.btn-order {
		display: block;
		float: left;
		padding: 0 10px;
		height: 33px;
		line-height: 33px;
		text-align: center;
		color: #fff;
		font-weight: 700;
		font-size: 14px;
		margin: 0 10px 15px 0;
		border-radius: 5px;
		box-shadow: 2px 3px 3px #afafaf;

		&.buy {
			background-color: red;
		}

		&.rent {
			background-color: green;
		}

		&.fix {
			background-color: orange;
		}
	}

	.note-form {
		font-size: 18px;
	}

	.share-socials {
		float: left;
		width: 100%;
		margin: 0 0 15px 0;

		.fb-share-button {
			vertical-align: top;

			* {
				vertical-align: top;
			}
		}
	}

	.related {
    float: left;
    width: 100%;
	}
}