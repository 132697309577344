.detail_infomation {
	.box_content.read {
		.title {
			border-bottom: 2px solid $primary-color;
			line-height: 35px;
			height: 35px;
			margin-bottom: 10px;
		}

		div,
		img {
			@media screen and (max-width: $tablet) {
				max-width: 100%;
				height: auto !important;
			}
		}
	}

	.related {
		ul {
			margin: 0;
			padding: 0 12px;
		}
	}
}