aside.box {
	border: 1px solid $divider-color;
	float: left;
	margin-bottom: 20px;
	width: 100%;

	.title {
		border: none;
		background-color: $primary-color;
		color: white;
		padding: 0 15px;
		box-sizing: border-box;
	}

	&.support {
		.supporters {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0 10px;

			> li {
				float: left;
				width: 100%;
				border-bottom: 1px solid #ddd;
				padding-bottom: 5px;
				margin-bottom: 5px;

				&:last-child {
					border-bottom: 0;
				}

				.supporter {
					list-style: none;
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;

					li {
						float: left;
						width: 100%;

						&.name {
							font-weight: bold;
						}

						&.phone {
							color: red;
						}

						&.email {
							color: green;
						}

						.glyphicon {
							margin-right: 10px;
							color: black;
						}
					}
				}
			}
		}

		.social {
			text-align: center;
			list-style: none;
			float: left;
			width: 100%;
			margin: 10px 0 0 0;
			padding: 0;

			li {
				display: inline-block;
				height: 27px;
				width: 27px;
				margin: 0;

				&.facebook {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -7px -5px;
					background-size: 154px;
				}

				&.k {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -34px -5px;
					background-size: 154px;
				}

				&.gmail {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -62px -5px;
					background-size: 154px;
				}

				&.gplus {
					background-image: url('img/social_support_sidebar.jpg');
					background-repeat: no-repeat;
					background-position: -91px -5px;
					background-size: 154px;
				}
			}
		}
	}

	&.s_post {
		.post-thumb-list {
			float: left;
			width: 100%;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				background: white;
				width: 100%;
				float: left;
				padding: 5px;
				padding-left: 5px;
				border-bottom: 1px solid #efefef;
				position: relative;

				a {
					width: 100%;
					float: left;
					height: 100%;
					color: $text-color;

					.thumb {
						float: left;

						img {
							margin-right:: 10px;
						}
					}

					.name {
						float: left;
						width: calc(100% - 90px);
					}
				}
			}
		}
	}

	&.likebox {
		.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
			width: 100% !important;
		}
	}

	&.adv_left_out {
		ul {
			list-style: none;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}

	&.adv {
		img {
			border-top: 1px solid $divider-color;
		}
	}

	&.product_run {
		@media screen and (max-width: $tablet) {
			display: none;
		}

		.owl-carousel {
			width: 100%;
			float: left;
		}

		.name {
			text-align: center;
			padding: 0 20px;
			margin: 15px 0;
		}
	}

	&.counter {
		@media screen and (max-width: $tablet) {
			display: none;
		}
	}

	.block_title {
		border: none;

		h2 {
			background-color: $primary-color;
			color: white;
			padding: 0 15px;
			box-sizing: border-box;

			.glyphicon {
				margin-right: 10px;
			}
		}
	}

	&.information-category {
		.tree {
			> ul {
				list-style: none;
				padding: 0 10px;
				width: 100%;
				float: left;
				margin: 0;

				> li {
					padding: 0 !important;
				}

				ul {
					width: 100%;
					float: left;
					margin: 0;
					padding: 0 0 0 20px;

					li {
						width: 100%;
						float: left;
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
}

.tree {
	list-style: none;
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;

	li {
		float: left;
		width: 100%;
		line-height: 30px;
		padding: 2px 10px;
		border-bottom: 1px solid $divider-color;

		&:last-child {
			border-bottom: none;
		}

		a {
			text-transform: uppercase;
			color: $text-color;
			font-size: 13px;
		}
	}
}