.heading {
	text-transform: uppercase;
	color: $primary-color;
	font-weight: bold;
	border-bottom: 2px solid $primary-color;
	margin: 20px 0 15px 0;
	background-image: url('img/heading_icon.png');
	background-repeat: no-repeat;
	padding: 0 0 0 25px;
	background-size: 15px;
	background-position: 0px 3px;
}

#owl-product {
	margin-top: 15px;

	.item {
		text-align: center;

		.name {
			color: $text-color;
			text-transform: uppercase;
			font-weight: bold;
		}
	}
}


#home-news {
	.post {
		margin-bottom: 15px;

		.thumb {
			a {
				float: left;
			}
		}

		.infos {
			background-color: white;
			padding: 0;
			float: left;
			width: 100%;

			.name {
				margin: 5px 0;
				float: left;
				width: 100%;
				text-transform: uppercase;
				font-weight: bold;

				a {
					color: $text-color;
					width: 100%;

					&:hover {
						color: $text-color;
					}
				}
			}

			.datetime {
				color: #7d7d7d;
				margin-bottom: 5px;
				float: left;
				width: 100%;
				display: none;
			}
		}
	}
}

// Đối tác khách hàng
#our-customer {
	float: left;
	width: 100%;

	.customer-title {
		text-align: center;
		color: $primary-color;
		text-transform: uppercase;
		border-bottom: 3px solid $primary-color;
		font-size: 16px;
		font-weight: bold;
		padding: 0 0 6px 0
	}
}

.box_banner {
	margin-bottom: 10px;
	position: relative;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		padding: 0 50px;
		height: 160px !important;

		li {
			float: left;
			border: 1px solid $divider-color;
			margin: 0;
			margin-right: 30px;
		}
	}

	.btn-prev {
		background: url('img/owl-left.png');
		background-repeat: no-repeat;
		width: 35px;
		height: 40px;
		background-size: 13px auto;
		background-position: 12px 0px;
		position: absolute;
		left: 0;
		top: 50px;
		cursor: pointer;
	}

	.btn-next {
		background: url('img/owl-right.png');
		background-repeat: no-repeat;
		width: 35px;
		height: 40px;
		background-size: 13px auto;
		background-position: 12px 0px;
		position: absolute;
		right: 0;
		top: 50px;
		cursor: pointer;
	}

	.caroufredsel_wrapper {
		width: 100% !important;
	}
}
