.list_tag {
	.title {
		margin-bottom: 10px;
	}

	.tab_container {
		width: 100% !important;

		.box_post.ajax_item {
			width: 100%;
			float: left;
			margin-top: 10px;

			.box_post_bottom {
				float: left;

				.thumb {
					float: left;
					margin: 0 10px 0 5px;
				}

				.box_post_info {
					float: left;
					width: calc(100% - 140px);
				}
			}
		}
	}
}

#content div.tag {
	padding: 7px 0 8px;
}

#content div.tag span {
	background: url(img/tag.png) left center no-repeat;
	font-weight: bold;
	display: block;
	float: left;
	width: 82px;
	height: 22px;
	line-height: 26px;
	text-align: right;
	margin-right: 5px;
	text-transform: uppercase;
	color: $primary-color;
}

#content div.tag a {
	color: $primary-color;
}