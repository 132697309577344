#contact-page {
	.envelop {
		@media screen and (max-width: $tablet) {
			margin-bottom: 15px;
		}

		@media screen and (min-width: $tablet + 1px) {
			margin-top: 42px;
		}
	}

	#ContactIndexForm {
		.note-form {
			margin-bottom: 15px;
		}

		.btn-submit {
			@media screen and (min-width: $tablet + 1px) {
				margin-top: 20px;
			}
		}
	}
}